<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="报告名称" prop="name">
                    <el-input
                        v-model="formInline.name"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div v-if="isShow">
                <el-button type="primary" plain @click="addClick"
                    >新增</el-button
                >
                <el-button type="danger" plain @click="allDelteClick"
                    >删除</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="id" label="#" width="55">
                </el-table-column>
                <el-table-column prop="name" label="名称"  >
                </el-table-column>
                <el-table-column
                    prop="uploadTime"
                    label="时间"
                     
                >
                    <template slot-scope="scope">{{
                        scope.row.uploadTime | dayjs
                    }}</template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-button v-if="isShow" type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="previewClick(scope.row)"
                            >预览</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
            <div>
                <el-form label-width="140px" ref="ruleForm" :model="formLabelAlign" :rules="rules">
                    <el-row :gutter="24">
                        <el-col :span="15">
                            <el-form-item label="名称：" prop="name">
                                <el-input
                                    v-model="formLabelAlign.name"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="15"
                            ><el-form-item label="开始时间：">
                                <el-date-picker
                                    v-model="formLabelAlign.showStart"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="开始时间"
                                    style="width:100%"
                                >
                                </el-date-picker>
                                <!-- <el-input v-model="formLabelAlign.region"></el-input> -->
                            </el-form-item></el-col
                        >
                        <el-col :span="15"
                            ><el-form-item label="结束时间：">
                                <el-date-picker
                                    v-model="formLabelAlign.showEnd"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="结束时间"
                                    style="width:100%"
                                >
                                </el-date-picker>
                                <!-- <el-input v-model="formLabelAlign.type"></el-input> -->
                            </el-form-item></el-col
                        >
                    </el-row>

                    <el-form-item label="选择报告（必填）：" prop="path">
                        <FileUploadReport
                            @input="coursewareClick"
                            :fileType="coursewareFileType"
                            :fileSize="20"
                            :limit="1"
                            :drag="false"
                            :actions="actions"
                        ></FileUploadReport>
                        <span style="color:red;font-size:12px">{{message}}</span>
                    </el-form-item>
                    
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button :loading="isSubmit" type="primary" @click="editSubmitClick('ruleForm')"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import FileUploadReport from '@/components/FileUploadReport'
import Pagination from '@/components/Pagination'
import {
    reportListApi,
    reportUpdateApi,
    reportAddApi,
    reportDeleteApi,
} from '@/api/reportManagement'
export default {
    // 注册组件
    components: {
        Pagination,
        FileUploadReport,
    },
    data() {
        return {
            message:'',
            isSubmit:false,
            actions: process.env.VUE_APP_BASE_API + '/upload/report', // 上传文件服务器地址
            coursewareFileType: ['pdf'],
            fileList: [],
            rolesList: [
                {
                    label: '是',
                    value: '是',
                },
                {
                    label: '否',
                    value: '否',
                },
            ],
             rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ],
                // path: [
                //     { required: true, message: '请选择文件', trigger: 'change' }
                // ],
                
            },
            title: '',
            dialogVisible: false,
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
            disabled: false,
						isShow:false
        }
    },
    created() {
			//角色
			const status = JSON.parse(localStorage.getItem('adminUser')).status;
			if(status == 20 || status == 10){
				this.isShow = true
			}else{
				this.isShow = false
			}
			if (sessionStorage.getItem('formInline')) {
					this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
			}
			this.reportListFun()
    },
    methods: {
        async reportListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr: 'id desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await reportListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.reportListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.reportListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.reportListFun(parm.currentPage, parm.pageSize)
        },
        // 批量删除
        async allDelteClick() {
            var ids = this.multipleSelection.map((item) => item.id)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await reportDeleteApi(params)

                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.reportListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        // 新增
        addClick() {
            this.formLabelAlign = {}
            this.title = '新增'
            this.dialogVisible = true
            this.message=''
        },
        // 预览
        async previewClick(row) {
            if (row.id) {
                var url =
                    'https://v2.wenjuan.ak.sdcom.gov.cn/file/pdf/' +
                    row.id +
                    '.pdf'
                window.open(url)
            } else {
                this.$message({
                    message: '没有上传报告， 无法预览',
                    type: 'error',
                })
            }
        },
        // 编辑模板
        editClick(row) {
            this.formLabelAlign = {}
            this.title = '编辑'
            this.formLabelAlign = row
            this.dialogVisible = true
            this.message=''
        },
        // 编辑提交
        editSubmitClick(formName) {
            this.isSubmit=true
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // console.log('this.formLabelAlign.name && this.path', this.formLabelAlign.name , this.path);
                    if(this.formLabelAlign.name && this.formLabelAlign.path){
                        if (this.title === '编辑') {
                            this.reportUpdateFun()
                        } else {
                            this.reportAddFun()
                        }
                    }else{
                        this.isSubmit=false
                        this.message='请选择文件'
                    }
                    
                } else {
                    this.isSubmit=false
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!');
                    return false;
                }
            });
           
        },
        // 新增提交
        async reportAddFun() {
            var res = await reportAddApi(this.formLabelAlign)
            if (res.code === 200) {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                })
                this.isSubmit=false
                this.dialogVisible = false
                this.reportListFun()
            } else {
                this.isSubmit=false
                this.$message({
                    message: '新增失败',
                    type: 'error',
                })
            }
        },
        // 更新数据
        async reportUpdateFun() {
            delete this.formLabelAlign.uploadTime
            var res = await reportUpdateApi(this.formLabelAlign)
            if (res.code === 200) {
                this.$message({
                    message: '更新成功',
                    type: 'success',
                })
                this.isSubmit=false
                this.dialogVisible = false
                this.reportListFun()
            } else {
                this.isSubmit=false
                this.$message({
                    message: '更新失败',
                    type: 'error',
                })
            }
        },
        // 选择报告
        coursewareClick(value) {
            this.formLabelAlign.id = value.strs
            this.formLabelAlign.path = value.names
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
